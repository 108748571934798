import { computed, inject, ref, watch } from "@vue/composition-api"
import _ from "lodash"

import { db } from "@/firebase"
import useStore from "@/use/useStore"
import useViewer from "@/use/useViewer"

const DEFAULT_TEAM_SIZE = 3

export default function useDiscussionGroup() {
  const $theme: { get: (_: string) => string } = inject("$theme")

  const { store } = useStore()
  const { viewer } = useViewer()

  const triangleShift = ref(0)

  const gameID = computed(() => store?.getters.game?.id)
  const mission = computed(() => store?.getters.getCurrentMission || {})

  const databaseRef = computed(() => {
    return db
      .auxiliary()
      .ref(`_room/${gameID.value}/triangleShift/${mission.value.id}`)
  })

  const team = computed(() => store.getters.chats?.[displayedUsersTeamId.value])

  const instructions = computed(() => {
    const texts = mission.value?.instructions.trim().split(";").filter(Boolean)

    return texts[triangleShift.value % texts.length]
  })

  const displayedUsersTeamId = computed(() => {
    if (store.getters["group/canViewerSelectGlobalTeam"])
      return store.getters["group/globalTeamID"]

    return viewer.value?.teamID
  })

  const roles = computed(() => {
    const roles = mission.value?.notes.split(";")
    const color1 = $theme.get("--tertiary-accent-color")
    const color2 = $theme.get("--secondary-accent-color")
    const color3 = $theme.get("--primary-accent-color")
    return [
      { label: roles?.[0] || "A) ASK ", color: color1 },
      { label: roles?.[1] || "B) LISTEN ", color: color2 },
      { label: roles?.[2] || "C) REFLECT ", color: color3 }
    ]
  })

  const users = computed(() => {
    const values =
      store.getters.onlineUsersGroupedByTeam[displayedUsersTeamId.value]?.slice(
        0,
        DEFAULT_TEAM_SIZE
      ) || []

    const shift = triangleShift.value % values.length
    const tail = _.take(values, shift)
    const head = _.drop(values, shift)
    const sorted: Object[] = [...head, ...tail]
    return sorted.map((user, index) => ({
      ...user,
      label: roles.value[index]?.label,
      color: roles.value[index]?.color
    }))
  })

  watch(
    databaseRef,
    (newValue, oldValue) => {
      oldValue?.off("value", onUpdate)
      newValue?.on("value", onUpdate)
    },
    { immediate: true }
  )

  function onUpdate(snapshot) {
    triangleShift.value = snapshot.val() || 0
  }

  return {
    databaseRef,
    DEFAULT_TEAM_SIZE,
    displayedUsersTeamId,
    instructions,
    mission,
    roles,
    team,
    triangleShift,
    users
  }
}
